<template>
  <monitoring-items
    :activeModules="activeModules"
    :mapItems="mapGovResolutionsItems"
    :api="{
      moduleId: 'pk_reg',
      modulesWithName: 'custom_query_gov_resolution_with_names',
      modules: 'module_gov_resolution_header',
      favoriteHated: 'favorite_and_hated_gov_resolutions_header',
      favoriteHatedResponse: 'favorite_and_hateful_gov_resolutions_list',
      bookmarks: 'bookmarks/fetchGovResolutions',
    }"
    #default="{items}"
  >
    <gov-resolutions-list :items="items" />
  </monitoring-items>
</template>

<script>
import MonitoringItems from '../components/MonitoringItems';
import GovResolutionsList from '../../GovResolutions/GovResolutionsList';
import { mapGovResolutionsItems } from '../../GovResolutions/mapGovResolutionsItems';

export default {
  props: {
    activeModules: Array,
  },
  components: {
    MonitoringItems,
    GovResolutionsList,
  },
  metaInfo() {
    return {
      title: 'Monitoring uchwał Rady Ministrów',
    };
  },
  data() {
    return {
      mapGovResolutionsItems,
    };
  },
};
</script>
