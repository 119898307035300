var render = function render(){var _vm=this,_c=_vm._self._c;return _c('monitoring-items',{attrs:{"activeModules":_vm.activeModules,"mapItems":_vm.mapGovResolutionsItems,"api":{
    moduleId: 'pk_reg',
    modulesWithName: 'custom_query_gov_resolution_with_names',
    modules: 'module_gov_resolution_header',
    favoriteHated: 'favorite_and_hated_gov_resolutions_header',
    favoriteHatedResponse: 'favorite_and_hateful_gov_resolutions_list',
    bookmarks: 'bookmarks/fetchGovResolutions',
  }},scopedSlots:_vm._u([{key:"default",fn:function({items}){return [_c('gov-resolutions-list',{attrs:{"items":items}})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }